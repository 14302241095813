import * as React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import { StaticQuery, graphql } from 'gatsby';
import ReactMarkdown from 'react-markdown';
import { LocationLanguageContextType } from '../typings';
import { LocationLanguageContext } from '../contexts/LocationLanguageContext';
import { Languages } from '../utils/getAllSupportedLanguages';

interface StrapiComingSoonPage {
  ComingSoonLabel: string;
  Description: string;
  locale: string;
}

interface ComingSoonPageQueryProps {
  allStrapiComingSoonPage: {
    nodes: StrapiComingSoonPage[];
  };
}

const ComingSoonPage = () => (
  <StaticQuery
    query={graphql`
      query ComingSoonPageQuery {
        allStrapiComingSoonPage {
          nodes {
            ComingSoonLabel
            Description
            locale
          }
        }
      }
    `}
    render={(data: ComingSoonPageQueryProps) => {
      const locationLanguage = React.useContext<LocationLanguageContextType | null>(LocationLanguageContext);
      const language: string = locationLanguage?.language ?? Languages.English;
      const comingSoonPageContent = data.allStrapiComingSoonPage.nodes.find((x: any) => x.locale === language);
      return (
        <Container maxWidth='xl' style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
          {comingSoonPageContent ? (
            <>
              <StaticImage
                layout='constrained'
                placeholder='tracedSVG'
                src='../assets/ComingSoon/coming-soon.png'
                alt='Scaffold'
                style={{ margin: '2.5em' }}
              />
              <Typography variant='h6'>{comingSoonPageContent.ComingSoonLabel}</Typography>
              <Typography component='div' style={{ marginBottom: '2em' }}>
                <ReactMarkdown children={comingSoonPageContent.Description} className='markdown-text' />
              </Typography>
            </>
          ) : (
            <Typography variant='body1' />
          )}
        </Container>
      );
    }}
  />
);

export default ComingSoonPage;
